var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-80 mx-auto"},[_c('simple-wizard',{attrs:{"editable":_vm.editable,"prev-route":_vm.prevRoute,"are-tabs-available":_vm.areTabsAvailable},on:{"update:startIndex":_vm.onTabChange}},[_c('template',{slot:"header"},[_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.editable)?_c('h5',{staticClass:"category"},[_vm._v(" Complete all the form. ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center",class:{
            'mb-3': _vm.editable
          }},[(!_vm.editable)?_c('md-button',{staticClass:"md-warning mt-3",on:{"click":function($event){_vm.editable=true}}},[_c('md-icon',{staticClass:"material-icons mr-2"},[_vm._v(" edit ")]),_vm._v(" "+_vm._s(_vm.editButtonText)+" ")],1):_vm._e(),_c('md-button',{staticClass:"md-primary mt-3 ml-1",attrs:{"to":_vm.prevRoute}},[_vm._v(" Return ")])],1),(!_vm.editable)?_c('small',{staticClass:"d-block mb-3"},[_vm._v("\"Fully Paid\" Programmes are not editable")]):_vm._e(),(_vm.$route.name === 'StudentFinancial' && _vm.studentData)?_c('StudentData',{attrs:{"name":_vm.studentData.full_name,"number":_vm.studentData.student_number,"email":_vm.studentData.email}}):_vm._e(),(_vm.$route.name==='StudentFinancial')?_c('StudentProgrammesSelect',{staticClass:"programmeSelect",attrs:{"student-id":_vm.$route.params.id},model:{value:(_vm.selProgId),callback:function ($$v) {_vm.selProgId=$$v},expression:"selProgId"}}):_vm._e()],1),(_vm.stepsShown.includes(1))?_c('wizard-tab',{attrs:{"before-change":function () { return _vm.validateStep('step1'); }}},[_c('template',{slot:"label"},[_vm._v(" Student ")]),_c('first-step',{ref:"step1",attrs:{"first-data":{
            image: _vm.wizardModel.image,
            image_url: _vm.wizardModel.image_url,
            firstName: _vm.wizardModel.firstName,
            lastName: _vm.wizardModel.lastName,
            country_id: _vm.wizardModel.country_id,
            gender: _vm.wizardModel.gender,
            date_of_birth: _vm.wizardModel.date_of_birth,
            email: _vm.wizardModel.email,
            phone: _vm.wizardModel.phone,
            emergency_contact: _vm.wizardModel.emergency_contact,
            emergency_contact_name: _vm.wizardModel.emergency_contact_name,
            mkt_source_id: _vm.wizardModel.mkt_source_id,
            passports: _vm.wizardModel.passports,
            addresses: _vm.wizardModel.addresses,
            flight: _vm.wizardModel.flight,
          }},on:{"on-validated":_vm.onStepValidated}})],2):_vm._e(),(_vm.stepsShown.includes(2))?_c('wizard-tab',{attrs:{"before-change":function () { return _vm.validateStep('step2'); }}},[_c('template',{slot:"label"},[_vm._v(" Programme ")]),_c('second-step',{ref:"step2",attrs:{"editable":_vm.$route.name !== 'StudentFinancial',"first-data":{
            mkt_source_id: _vm.studentData ? _vm.studentData.mkt_source_id : null,
            class_schedule_id: _vm.wizardModel.class_schedule_id,
            course_id: _vm.wizardModel.course_id,
            start_date: _vm.wizardModel.start_date,
            course_weeks: _vm.wizardModel.course_weeks,
            holiday_weeks: _vm.wizardModel.holiday_weeks,
            visa_type: _vm.wizardModel.visa_type,
            agent_id: _vm.wizardModel.agent_id,
            commission_id: _vm.wizardModel.commission_id,
            template_id: _vm.wizardModel.template_id,
            already_signed: _vm.wizardModel.already_signed,
            terms_file: _vm.wizardModel.terms_file,
            exam_id: _vm.wizardModel.exam_id,
            exam_expire_at: _vm.wizardModel.exam_expire_at,
            no_date: _vm.wizardModel.no_date,
          }},on:{"on-validated":_vm.onStepValidated}})],2):_vm._e(),(_vm.stepsShown.includes(3))?_c('wizard-tab',{attrs:{"before-change":function () { return _vm.validateStep('step3'); }}},[_c('template',{slot:"label"},[_vm._v(" Package ")]),_c('third-step',{ref:"step3",attrs:{"editable":_vm.editable
            && (
              !_vm.selProgramme
              || _vm.$route.name === 'PackageDetails' || [
                'Waiting for Approval',
                'Unpaid',
                'Partially Paid',
                'Pre-Enrolled',
                'Fully Paid'
              ].includes(_vm.selProgramme.status)
            ),"first-data":{
            package_items: _vm.wizardModel.package_items
          },"programme-status":_vm.selProgramme ? _vm.selProgramme.status : null},on:{"on-validated":_vm.onStepValidated}})],2):_vm._e(),(_vm.stepsShown.includes(4))?_c('wizard-tab',{attrs:{"before-change":function () { return _vm.validateStep('step4'); }}},[_c('template',{slot:"label"},[_vm._v(" Payments ")]),_c('fourth-step',{ref:"step4",attrs:{"editable":_vm.editable && (!_vm.selProgramme || _vm.selProgramme.status !== 'Fully Paid'),"first-data":{
            package_items: _vm.wizardModel.package_items,
            package_payments: _vm.wizardModel.package_payments,
            incomes: _vm.wizardModel.incomes,
            expenditures: _vm.wizardModel.expenditures,
            studentData: _vm.studentData || null,
            selProgramme: _vm.selProgramme || null,
          }},on:{"on-validated":_vm.onStepValidated}})],2):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }