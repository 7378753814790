<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-80 mx-auto">
      <simple-wizard
        :editable="editable"
        :prev-route="prevRoute"
        :are-tabs-available="areTabsAvailable"
        @update:startIndex="onTabChange"
      >
        <template slot="header">
          <h3
            class="title"
          >
            {{ title }}
          </h3>
          <h5
            v-if="editable"
            class="category"
          >
            Complete all the form.
          </h5>
          <div
            class="d-flex justify-content-center"
            :class="{
              'mb-3': editable
            }"
          >
            <md-button
              v-if="!editable"
              class="md-warning mt-3"
              @click="editable=true"
            >
              <md-icon class="material-icons mr-2">
                edit
              </md-icon>
              {{ editButtonText }}
            </md-button>

            <md-button
              class="md-primary mt-3 ml-1"
              :to="prevRoute"
            >
              Return
            </md-button>
          </div>
          <small
            v-if="!editable"
            class="d-block mb-3"
          >"Fully Paid" Programmes are not editable</small>

          <StudentData
            v-if="$route.name === 'StudentFinancial' && studentData"
            :name="studentData.full_name"
            :number="studentData.student_number"
            :email="studentData.email"
          />

          <StudentProgrammesSelect
            v-if="$route.name==='StudentFinancial'"
            v-model="selProgId"
            :student-id="$route.params.id"
            class="programmeSelect"
          />
        </template>

        <wizard-tab
          v-if="stepsShown.includes(1)"
          :before-change="() => validateStep('step1')"
        >
          <template slot="label">
            Student
          </template>
          <first-step
            ref="step1"
            :first-data="{
              image: wizardModel.image,
              image_url: wizardModel.image_url,
              firstName: wizardModel.firstName,
              lastName: wizardModel.lastName,
              country_id: wizardModel.country_id,
              gender: wizardModel.gender,
              date_of_birth: wizardModel.date_of_birth,
              email: wizardModel.email,
              phone: wizardModel.phone,
              emergency_contact: wizardModel.emergency_contact,
              emergency_contact_name: wizardModel.emergency_contact_name,
              mkt_source_id: wizardModel.mkt_source_id,
              passports: wizardModel.passports,
              addresses: wizardModel.addresses,
              flight: wizardModel.flight,
            }"
            @on-validated="onStepValidated"
          />
        </wizard-tab>

        <wizard-tab
          v-if="stepsShown.includes(2)"
          :before-change="() => validateStep('step2')"
        >
          <template slot="label">
            Programme
          </template>
          <second-step
            ref="step2"
            :editable="$route.name !== 'StudentFinancial'"
            :first-data="{
              mkt_source_id: studentData ? studentData.mkt_source_id : null,
              class_schedule_id: wizardModel.class_schedule_id,
              course_id: wizardModel.course_id,
              start_date: wizardModel.start_date,
              course_weeks: wizardModel.course_weeks,
              holiday_weeks: wizardModel.holiday_weeks,
              visa_type: wizardModel.visa_type,
              agent_id: wizardModel.agent_id,
              commission_id: wizardModel.commission_id,
              template_id: wizardModel.template_id,
              already_signed: wizardModel.already_signed,
              terms_file: wizardModel.terms_file,
              exam_id: wizardModel.exam_id,
              exam_expire_at: wizardModel.exam_expire_at,
              no_date: wizardModel.no_date,
            }"
            @on-validated="onStepValidated"
          />
        </wizard-tab>

        <wizard-tab
          v-if="stepsShown.includes(3)"
          :before-change="() => validateStep('step3')"
        >
          <template slot="label">
            Package
          </template>
          <third-step
            ref="step3"
            :editable="editable
              && (
                !selProgramme
                || $route.name === 'PackageDetails' || [
                  'Waiting for Approval',
                  'Unpaid',
                  'Partially Paid',
                  'Pre-Enrolled',
                  'Fully Paid'
                ].includes(selProgramme.status)
              )"
            :first-data="{
              package_items: wizardModel.package_items
            }"
            :programme-status="selProgramme ? selProgramme.status : null"
            @on-validated="onStepValidated"
          />
        </wizard-tab>

        <wizard-tab
          v-if="stepsShown.includes(4)"
          :before-change="() => validateStep('step4')"
        >
          <template slot="label">
            Payments
          </template>
          <fourth-step
            ref="step4"
            :editable="editable && (!selProgramme || selProgramme.status !== 'Fully Paid')"
            :first-data="{
              package_items: wizardModel.package_items,
              package_payments: wizardModel.package_payments,
              incomes: wizardModel.incomes,
              expenditures: wizardModel.expenditures,
              studentData: studentData || null,
              selProgramme: selProgramme || null,
            }"
            @on-validated="onStepValidated"
          />
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import { SimpleWizard, WizardTab } from '@/components';
import { StudentProgrammesSelect } from '@/components/Inputs/selects';
import Swal from 'sweetalert2';
import StudentData from '@/components/StudentData.vue';
import moment from 'moment';
import FirstStep from './Form/FirstStep.vue';
import SecondStep from './Form/SecondStep.vue';
import ThirdStep from './Form/ThirdStep.vue';
import FourthStep from './Form/FourthStep.vue';

export default {
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    SimpleWizard,
    WizardTab,
    StudentProgrammesSelect,
    StudentData,
  },
  data() {
    return {
      wizardModel: {
        // First Step
        image: null,
        image_url: null,
        firstName: null,
        lastName: null,
        country: null,
        country_id: null,
        gender: 'M',
        date_of_birth: null,
        email: null,
        phone: null,
        emergency_contact: null,
        emergency_contact_name: null,
        mkt_source_id: null,
        passports: [],
        addresses: [],
        flight: null,
        passport_file: [],

        // Second Step
        course_id: null,
        visa_type: null,
        start_date: null,
        course_weeks: null,
        holiday_weeks: null,
        agent_id: null,
        commission_id: null,
        template_id: null,
        already_signed: false,
        terms_file: null,
        exam_id: null,
        exam_expire_at: null,
        no_date: null,
        class_schedule_id: null,

        // Third Step
        package_items: [],

        // Fourth Step
        package_payments: [
          {
            key: Math.random() * Date.now(),
            expected_date: null,
            amount: 0,
            payment_method_id: null,
          },
        ],
      },
      studentData: null,
      stepsShown: [],
      title: '',
      prevRoute: null,
      editable: true,
      editButtonText: null,
      programmes: [],
      selProgramme: null,
      selProgId: null,
      selPackageId: null,
      areTabsAvailable: false,
    };
  },
  watch: {
    selProgId(val) {
      let prog = this.programmes.filter((x) => x.programme_id === val);
      if (!prog.length) {
        this.wizardModel = {
          // Second Step
          course_id: null,
          visa_type: null,
          start_date: null,
          course_weeks: null,
          holiday_weeks: null,
          agent_id: null,
          commission_id: null,
          template_id: null,
          already_signed: false,
          terms_file: null,
          exam_id: null,
          exam_expire_at: null,
          no_date: null,
          class_schedule_id: null,

          // Third Step
          package_items: [],

          // Fourth Step
          package_payments: [
            {
              key: Math.random() * Date.now(),
              expected_date: null,
              amount: 0,
              payment_method_id: null,
            },
          ],
        };
      }

      prog = prog[0];
      this.selProgramme = prog;
      this.selPackageId = prog.package_id;

      this.wizardModel = {
        // Second Step
        course_id: prog.course_id,
        visa_type: prog.visa_type,
        start_date: prog.start_date,
        course_weeks: prog.course_weeks,
        holiday_weeks: prog.holiday_weeks,
        agent_id: prog.agent_id,
        commission_id: prog.commission_id,
        template_id: prog.template_id,
        already_signed: prog.already_signed,
        terms_file: prog.terms_file,
        exam_id: prog.exam_id,
        exam_expire_at: prog.exam_expire_at,
        no_date: prog.no_date,
        class_schedule_id: prog.class_schedule_id,

        // Third Step
        package_items: prog.package.items.map((x) => {
          const aux = x;
          aux.key = Math.random() * Date.now();
          aux.price = x.package_package_item.price;
          aux.units = x.package_package_item.units;
          aux.used = x.package_package_item.used_at !== null;
          aux.is_invalid = x.package_package_item.is_invalid;
          return aux;
        }).sort((a) => {
          if (a.required) return -1;
          if (!a.required) return 1;
          return 0;
        }),

        // Fourth Step
        package_payments: prog.package.package_payments.map((x) => {
          const aux = x;
          aux.key = Math.random() * Date.now();
          aux.expected_date = x.payment_date;
          aux.final_payment_date = x.final_payment_date;
          aux.amount = x.total;
          return aux;
        }),
        incomes: this.wizardModel.incomes,
        expenditures: this.wizardModel.expenditures,
      };
    },
  },
  mounted() {
    switch (this.$route.name) {
      case 'NewStudent':
        this.stepsShown = [1, 2, 3, 4];
        this.title = 'New Student Form';
        this.editable = true;
        this.prevRoute = { name: 'Students' };

        if (!this.signRequestActive) {
          this.wizardModel.already_signed = true;
        }
        break;
      case 'EditStudent':
        this.stepsShown = [1];
        this.title = 'Edit Student Form';
        this.editable = true;
        this.getStudent(this.$route.params.id);
        this.prevRoute = window.history.length > 1 ? { name: 'StudentProfile', params: { id: this.$route.params.id } } : { name: 'Students' };
        break;
      case 'NewStudentProgramme':
        this.stepsShown = [2, 3, 4];
        this.editable = true;
        this.title = 'New Student Programme Form';
        this.prevRoute = { name: 'StudentProgrammes', params: { id: this.$route.params.id } };
        this.areTabsAvailable = true;

        if (!this.signRequestActive) {
          this.wizardModel.already_signed = true;
        }
        break;
      case 'PackageDetails':
        this.getPackage(this.$route.params.id);
        this.stepsShown = [3, 4];
        this.title = 'Package Details';
        this.editable = false;
        this.prevRoute = { name: 'Packages' };
        this.editButtonText = 'Edit Package';
        this.areTabsAvailable = true;
        break;
      case 'StudentFinancial':
        this.getStudentFinancial(this.$route.params.id);
        this.stepsShown = [2, 3, 4];
        this.title = 'Financial Details';
        this.editable = false;
        this.prevRoute = { name: 'StudentResume', params: { id: this.$route.params.id } };
        this.editButtonText = 'Edit Details';
        this.areTabsAvailable = true;
        break;
      default: break;
    }
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model, stepNumber) {
      if (validated) {
        Object.keys(model)
          .forEach((key) => {
            this.wizardModel[key] = model[key];
          });

        // If the step is the last we complete the wizard
        if (this.stepsShown.indexOf(stepNumber) === this.stepsShown.length - 1) {
          this.wizardComplete();
        }
        this.$forceUpdate();
      }
    },
    wizardComplete() {
      const { id } = this.$route.params;

      switch (this.$route.name) {
        case 'NewStudent':
          this.createStudent();
          break;
        case 'EditStudent':
          this.editStudent(id);
          break;
        case 'NewStudentProgramme':
          this.createProgrammeForStudent(id);
          break;
        case 'PackageDetails':
          this.savePackage(id);
          break;
        case 'StudentFinancial':
          this.savePackage(this.selPackageId);
          break;
        default: break;
      }
    },
    getStudent(id) {
      this.$nextTick(() => {
        this.request(`${this.$API.STUDENTS}${id}`, 'get', null, ({ data }) => {
          this.wizardModel = {
            // First Step
            image: data.profile_image,
            image_url: data.profile_image ? data.profile_image.url : null,
            firstName: data.first_name,
            lastName: data.last_name,
            country: data.country,
            country_id: data.country_id,
            gender: data.gender,
            date_of_birth: data.date_of_birth,
            email: data.email,
            phone: data.phone,
            emergency_contact: data.emergency_contact,
            emergency_contact_name: data.emergency_contact_name,
            mkt_source_id: data.mkt_source_id,
            flight: data.flight,
            terms_file: data.terms_file,
            addresses: [],
            passports: [],
          };

          if (data.flight) {
            this.wizardModel.flight.departure_date = moment(this.wizardModel.flight.departure_date).format('YYYY-MM-DD HH:mm');
            this.wizardModel.flight.arrival_date = moment(this.wizardModel.flight.arrival_date).format('YYYY-MM-DD HH:mm');
          }

          if (data.passports.length) {
            this.wizardModel.passports = data.passports;
          }

          if (data.addresses.length) {
            this.wizardModel.addresses = data.addresses;
          }
          this.$forceUpdate();
        });
      });
    },
    getStudentFinancial(id) {
      this.request(`${this.$API.STUDENTS}${id}`, 'get', null, ({ data }) => {
        this.programmes = data.programmes
          .sort((a, b) => (a.start_date < b.start_date ? 1 : -1))
          .map((x) => {
            const aux = x;
            aux.id = x.programme_id;
            aux.name = `${x.course.title} - ${x.status}`;
            return aux;
          });

        if (this.$route.query.pid) {
          this.selProgId = parseInt(this.$route.query.pid, 10);
        } else {
          this.selProgId = data.programmes[0].programme_id;
        }

        this.studentData = {
          full_name: data.full_name,
          first_name: data.first_name,
          last_name: data.last_name,
          student_number: data.student_number,
          email: data.email,
          mkt_source_id: data.mkt_source_id,
        };

        this.wizardModel = {
          // Second Step
          course_id: null,
          visa_type: null,
          start_date: null,
          course_weeks: null,
          holiday_weeks: null,
          agent_id: null,
          commission_id: null,
          exam_id: null,
          exam_expire_at: null,
          class_schedule_id: null,

          // Third Step
          package_items: [],

          // Fourth Step
          package_payments: [
            {
              key: Math.random() * Date.now(),
              expected_date: null,
              amount: 0,
              payment_method_id: null,
            },
          ],
          incomes: data.incomes,
          expenditures: data.expenditures,
        };
      });
    },
    getPackage(id) {
      this.request(`packages/${id}`, 'get', null, ({ data }) => {
        this.wizardModel = {
          // Third Step
          package_items: data.items.map((x) => {
            const aux = x;
            aux.key = Math.random() * Date.now();
            aux.price = x.package_package_item.price;
            aux.used = x.package_package_item.used_at !== null;
            aux.is_invalid = x.package_package_item.is_invalid;
            return aux;
          }).sort((a) => {
            if (a.required) return -1;
            if (!a.required) return 1;
            return 0;
          }),

          // Fourth Step
          package_payments: data.package_payments.map((x) => {
            const aux = x;
            aux.key = Math.random() * Date.now();
            aux.expected_date = x.payment_date;
            aux.final_payment_date = x.final_payment_date;
            aux.amount = x.total;
            return aux;
          }),
        };

        this.selProgramme = data.programme;

        this.$refs.step3.validate();

        this.$forceUpdate();
      });
    },
    createStudent() {
      const finalData = this.paramsSanitization(this.wizardModel);
      const formData = new FormData();

      // First Step
      formData.append('image', finalData.image);
      formData.append('firstName', finalData.firstName);
      formData.append('lastName', finalData.lastName);
      formData.append('country_id', finalData.country_id);
      formData.append('gender', finalData.gender);
      formData.append('date_of_birth', finalData.date_of_birth);
      formData.append('email', finalData.email);
      formData.append('phone', finalData.phone);
      formData.append('emergency_contact', finalData.emergency_contact);
      formData.append('emergency_contact_name', finalData.emergency_contact_name);
      formData.append('mkt_source_id', finalData.mkt_source_id);
      formData.append('addresses', JSON.stringify(finalData.addresses));
      formData.append('flight', JSON.stringify(finalData.flight));
      formData.append('flight_file', this.wizardModel.flight_file);

      this.wizardModel.passports.forEach(({ file, url, ...details }, ix) => {
        if (file && (file instanceof Blob || file instanceof File)) {
          // Added 3rd parameter (filename) to identify file in backend
          formData.append('passport_file', file, `passport_${ix}`);
        }
        formData.append(`passports[${ix}]`, JSON.stringify({
          ...this.paramsSanitization(details),
          identifier: `passport_${ix}`,
        }));
      });

      // Second Step
      formData.append('course_id', finalData.course_id);
      formData.append('visa_type', finalData.visa_type);
      formData.append('start_date', finalData.start_date);
      formData.append('course_weeks', finalData.course_weeks);
      formData.append('holiday_weeks', finalData.holiday_weeks);
      formData.append('agent_id', finalData.agent_id);
      formData.append('commission_id', finalData.commission_id);
      formData.append('template_id', finalData.template_id);
      formData.append('already_signed', finalData.already_signed);
      formData.append('terms_file', finalData.terms_file);
      formData.append('exam_id', finalData.exam_id);
      formData.append('exam_expire_at', finalData.exam_expire_at);
      formData.append('no_date', finalData.no_date);
      formData.append('class_schedule_id', finalData.class_schedule_id);

      // Third Step
      formData.append('package_items', JSON.stringify(finalData.package_items));

      // Fourth Step
      formData.append('package_payments', JSON.stringify(finalData.package_payments));

      this.request(`${this.$API.STUDENTS}`, 'post', formData, () => {
        this.fireSuccess('Student has been created!');
        this.$router.push({ name: 'Students' });
      });
    },
    editStudent(id) {
      const finalData = this.paramsSanitization(this.wizardModel);
      const formData = new FormData();

      // First Step
      formData.append('image', finalData.image);
      formData.append('firstName', finalData.firstName);
      formData.append('lastName', finalData.lastName);
      formData.append('country_id', finalData.country_id);
      formData.append('gender', finalData.gender);
      formData.append('date_of_birth', finalData.date_of_birth);
      formData.append('email', finalData.email);
      formData.append('phone', finalData.phone);
      formData.append('emergency_contact', finalData.emergency_contact);
      formData.append('emergency_contact_name', finalData.emergency_contact_name);
      formData.append('mkt_source_id', finalData.mkt_source_id);
      formData.append('addresses', JSON.stringify(finalData.addresses));
      formData.append('flight', JSON.stringify(finalData.flight));
      formData.append('flight_file', this.wizardModel.flight_file);

      this.wizardModel.passports.forEach(({ file, url, ...details }, ix) => {
        if (file && (file instanceof Blob || file instanceof File)) {
          // Added 3rd parameter (filename) to identify file in backend
          formData.append('passport_file', file, `passport_${ix}`);
        }
        formData.append(`passports[${ix}]`, JSON.stringify({
          ...this.paramsSanitization(details),
          identifier: `passport_${ix}`,
        }));
      });

      this.request(`${this.$API.STUDENTS}${id}`, 'put', formData, () => {
        this.fireSuccess('Student has been updated!');
        this.$router.push({ name: 'StudentProfile' });
      });
    },
    createProgrammeForStudent(id) {
      const finalData = this.paramsSanitization(this.wizardModel);

      const formData = new FormData();
      formData.append('template_id', finalData.template_id);
      formData.append('class_schedule_id', finalData.class_schedule_id);
      formData.append('course_id', finalData.course_id);
      formData.append('visa_type', finalData.visa_type);
      formData.append('start_date', finalData.start_date);
      formData.append('course_weeks', finalData.course_weeks);
      formData.append('holiday_weeks', finalData.holiday_weeks);
      formData.append('agent_id', finalData.agent_id);
      formData.append('commission_id', finalData.commission_id);
      formData.append('already_signed', finalData.already_signed);
      formData.append('terms_file', finalData.terms_file);
      formData.append('exam_id', finalData.exam_id);
      formData.append('exam_expire_at', finalData.exam_expire_at);
      formData.append('no_date', finalData.no_date);
      formData.append('package_items', JSON.stringify(finalData.package_items));
      formData.append('package_payments', JSON.stringify(finalData.package_payments));

      this.request(`${this.$API.STUDENTS}${id}/${this.$API.PROGRAMMES}`, 'post', formData, () => {
        this.loading = false;
        Swal.fire({
          title: 'Good job!',
          text: 'Programme has been created!',
          type: 'success',
          confirmButtonClass: 'md-button md-success',
          buttonsStyling: false,
        }).then(() => {
          this.$router.push({ name: 'StudentProgrammes', params: { id } });
        });
      });
    },
    savePackage(id) {
      const {
        // Third Step
        package_items,
        // Fourth Step
        package_payments,
      } = this.wizardModel;
      this.request(`packages/${id}`, 'put', {
        package_items,
        package_payments,
      }, () => {
        this.loading = false;
        Swal.fire({
          title: 'Good job!',
          text: 'Package has been updated!',
          type: 'success',
          confirmButtonClass: 'md-button md-success',
          buttonsStyling: false,
        }).then(() => {
          window.location.href = `${window.location.origin + window.location.pathname}?pid=${this.selProgId}`;
        });
      });
    },
    onTabChange(index) {
      const mainPanel = document.querySelector('.main-panel');
      mainPanel.scrollTo(0, 0);

      if (
        (this.$route.name === 'NewStudent' && index === 3)
        || (this.$route.name === 'NewStudentProgramme' && index === 2)
      ) {
        this.$refs.step4.updatePayments();
      }
    },
  },
};
</script>

<style>
.programmeSelect .md-field {
  margin-top: 50px;
  margin-bottom: 20px;
}
.programmeSelect .md-field.md-valid label {
  color: white !important;
}
.programmeSelect label {
  background: #4caf50;
  text-transform: uppercase;
  font-size: 1em !important;
  border-radius: .25em;
  padding: 8px 30px;
  margin-bottom: 20px;
  color: white !important;
  opacity: 1;
  top: -35px !important;
  width: 100%;
  left: 0 !important;
}
</style>
