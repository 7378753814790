<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div>
        <h5 class="info-text">
          Let's start with the basic information
        </h5>
        <div class="md-layout">
          <div class="md-layout-item md-size-40 md-small-size-100">
            <form-uploader
              class="imgSelector"
              @input="imageSelected"
              @error="fireError"
            >
              <div class="picture-container">
                <div class="picture">
                  <img
                    :src="form.image_url || avatar"
                    class="picture-src"
                  >
                </div>

                <h6 class="description mt-3">
                  Choose Picture
                </h6>
              </div>
            </form-uploader>
            <div class="text-center small-text">
              The image must be JPG or PNG<br>
              The dimensions must be 300x300<br>
              The size cannot be higher than 5mb
            </div>
          </div>
          <div class="md-layout-item md-size-60 mt-4 md-small-size-100">
            <form-text
              v-model="form.firstName"
              rules="required"
              name="firstName"
              icon="face"
              label="Given Name"
            />

            <form-text
              v-model="form.lastName"
              rules="required"
              name="lastName"
              label="Surname"
              icon="record_voice_over"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <CountriesAutocomplete
              v-model="form.country_id"
              rules="required"
              label="Country"
            />

            <form-radio
              v-model="form.gender"
              name="gender"
              :options="[
                {value:'M', label:'Male'},
                {value:'F', label:'Female'},
              ]"
            />

            <form-date
              v-model="form.date_of_birth"
              name="birth_date"
              label="Birth Date"
              rules="required"
            />

            <form-text
              v-model="form.email"
              icon="email"
              name="email"
              label="Email"
              rules="required|email"
            />

            <form-text
              v-model="form.phone"
              icon="phone"
              name="phone"
              label="Phone"
              rules="required"
            />

            <form-text
              v-model="form.emergency_contact_name"
              icon="person_outline"
              label="Emergency Contact Name (Relation)"
            />
            <form-text
              v-model="form.emergency_contact"
              icon="contact_page"
              label="Emergency Contact (Email / Phone)"
            />

            <MktSourcesSelect
              v-model="form.mkt_source_id"
              rules="required"
            />
          </div>

          <!-- Flight -->
          <div class="md-layout-item md-size-100">
            <md-button
              v-if="!form.flight"
              @click="addFlight"
            >
              Add Flight Data
            </md-button>

            <template v-else>
              <h5>Flight</h5>
              <FormText
                v-model="form.flight.flight_code"
                label="Flight Code"
                icon="money"
              />
              <FormDate
                v-model="form.flight.departure_date"
                date-time
                label="Departure Date"
              />
              <FormDate
                v-model="form.flight.arrival_date"
                date-time
                label="Arrival Date"
              />
              <FormText
                v-model="form.flight.airline"
                icon="airplanemode_active"
                label="Airline"
              />

              <div class="d-flex">
                <FormUploader
                  v-model="form.flight_file"
                >
                  <md-button class="md-info">
                    <md-icon>file_present</md-icon>
                    Upload Flight Voucher
                  </md-button>
                  <br>

                  <b
                    v-if="form.flight_file"
                    class="text-success"
                  >{{ form.flight_file.name }}</b>
                </FormUploader>

                <md-button
                  v-if="form.flight.file_id"
                  class="md-primary"
                  @click="openFileUrl(form.flight.file_id)"
                >
                  Download Flight File
                </md-button>
              </div>

              <small>File must be JPG/PNG/PDF max 5MB</small>
            </template>
          </div>

          <!-- Passports -->
          <div class="md-layout-item md-size-100">
            <md-button
              v-if="form.passports.length <= 3"
              @click="addPassport"
            >
              Add Passport
            </md-button>

            <template v-if="form.passports.length > 0">
              <h5>Passport</h5>
              <div class="md-layout">
                <div
                  v-for="passport in form.passports"
                  :key="passport.passport_id"
                  class="md-layout-item"
                >
                  <form-text
                    v-model="passport.number"
                    icon="card_travel"
                    label="Passport"
                    rules="required"
                  />

                  <CountriesAutocomplete
                    v-model="passport.country_id"
                    rules="required"
                    label="Passport Country"
                  />

                  <form-date
                    v-model="passport.issue_date"
                    name="passport_issue"
                    rules="required"
                    label="Passport Issue"
                  />

                  <form-date
                    v-model="passport.expiration_date"
                    name="passport_expiration"
                    rules="required"
                    label="Passport Expiration"
                  />

                  <div class="d-flex">
                    <form-uploader
                      v-model="passport.file"
                    >
                      <md-button class="md-success">
                        Upload Passport Photo
                      </md-button>
                    </form-uploader>

                    <md-button
                      v-if="passport.file_id"
                      class="md-primary"
                      @click="openFileUrl(passport.file_id)"
                    >
                      Download Passport Photo
                    </md-button>
                  </div>
                  <div v-if="passport.file">
                    <b class="text-success">{{ passport.file.name }}</b>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <!-- Addresses -->
          <div
            class="md-layout-item md-size-100"
          >
            <md-button
              v-if="!withAddressHome"
              @click="addAddress('Home')"
            >
              Add Home Address
            </md-button>
            <md-button
              v-if="!withAddressLocal"
              @click="addAddress('Local')"
            >
              Add Local Address
            </md-button>

            <div class="md-layout">
              <div
                v-if="withAddressHome || withAddressLocal"
                class="md-layout-item md-size-100"
              >
                <h5>Address</h5>
              </div>

              <div
                v-if="withAddressHome"
                class="md-layout-item"
              >
                <form-select
                  v-model="homeAddress.type"
                  name="address_type1"
                  icon="local_offer"
                  label="Address Type"
                  :options="address_types"
                  disabled
                  rules="required"
                />
                <form-text
                  v-model="homeAddress.address"
                  icon="my_location"
                  name="address1"
                  label="Address"
                  rules="required"
                />
                <form-text
                  v-model="homeAddress.city"
                  name="address_city1"
                  icon="local_offer"
                  label="City"
                  rules="required"
                />
                <form-text
                  v-model="homeAddress.county"
                  name="address_county1"
                  icon="local_offer"
                  label="Address County"
                  rules="required"
                />
                <CountriesAutocomplete
                  v-model="homeAddress.country_id"
                  rules="required"
                  label="Address Country"
                />
                <form-text
                  v-model="homeAddress.zip_code"
                  name="address_zip_code1"
                  icon="local_offer"
                  label="Address Zip Code"
                  rules="required"
                />
              </div>
              <div
                v-if="withAddressLocal"
                class="md-layout-item"
              >
                <form-select
                  v-model="localAddress.type"
                  name="address_type1"
                  icon="local_offer"
                  label="Address Type"
                  :options="address_types"
                  disabled
                  rules="required"
                />
                <form-text
                  v-model="localAddress.address"
                  icon="my_location"
                  name="address1"
                  label="Address"
                  rules="required"
                />
                <form-text
                  v-model="localAddress.city"
                  name="address_city1"
                  icon="local_offer"
                  label="City"
                  rules="required"
                />
                <form-text
                  v-model="localAddress.county"
                  name="address_county1"
                  icon="local_offer"
                  label="Address County"
                  rules="required"
                />
                <CountriesAutocomplete
                  v-model="localAddress.country_id"
                  rules="required"
                  label="Address Country"
                />
                <form-text
                  v-model="localAddress.zip_code"
                  name="address_zip_code1"
                  icon="local_offer"
                  label="Address Zip Code"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <image-cropper
      v-if="showCropper"
      :img="form.image_url"
      :img-type="image_type"
      @imageCropped="imageCropped"
      @close="showCropper = false"
    />
  </ValidationObserver>
</template>
<script>
/* eslint-disable max-len */
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import {
  FormUploader,
  FormRadio,
  FormText,
  FormDate,
  FormSelect,
} from '@/components/Inputs';
import {
  CountriesAutocomplete,
  MktSourcesSelect,
} from '@/components/Inputs/selects';
// eslint-disable-next-line no-unused-vars
import isCountry from '@/core/validators/isCountry';
import ImageCropper from '@/components/ImageCropper.vue';

extend('required', required);
extend('email', email);
extend('isCountry', isCountry);

export default {
  components: {
    FormRadio,
    FormText,
    FormDate,
    FormSelect,
    FormUploader,
    MktSourcesSelect,
    CountriesAutocomplete,
    ImageCropper,
  },
  props: {
    firstData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isCountryValid: false,
      form: {
        image: null,
        image_url: null,
        firstName: null,
        lastName: null,
        country: null,
        country_id: null,
        gender: 'M',
        date_of_birth: null,
        email: null,
        phone: null,
        emergency_contact: null,
        emergency_contact_name: null,
        mkt_source_id: null,
        passports: [],
        addresses: [],
        flight: null,
        flight_file: null,
      },
      flightFileURL: null,
      avatar: '/img/default-avatar.png',
      address_types: [
        { id: 'Local', name: 'Local' },
        { id: 'Home', name: 'Home' },
      ],
      withAddressHome: false,
      withAddressLocal: false,
      homeAddress: null,
      localAddress: null,
      showCropper: false,
      imageType: null,
    };
  },
  /* eslint-disable camelcase */
  watch: {
    firstData: {
      immediate: true,
      handler(val) {
        Object.keys(this.form)
          .forEach((key) => {
            this.form[key] = val[key];
          });

        if (this.form.addresses) {
          if (this.form.addresses.filter((x) => x.type === 'Home').length) {
            this.homeAddress = this.form.addresses.find((x) => x.type === 'Home');
            this.withAddressHome = true;
          }

          if (this.form.addresses.filter((x) => x.type === 'Local').length) {
            this.localAddress = this.form.addresses.find((x) => x.type === 'Local');
            this.withAddressLocal = true;
          }
        }
      },
    },
  },
  methods: {
    validate() {
      // Filling Ids got by autocomplete
      this.form.addresses = [];

      if (this.homeAddress !== null) {
        this.form.addresses.push(this.homeAddress);
      }

      if (this.localAddress !== null) {
        this.form.addresses.push(this.localAddress);
      }

      return this.$refs.form.validate().then((res) => {
        this.$emit('on-validated', res, this.form, 1);
        return res;
      });
    },
    addPassport() {
      this.form.passports = [
        ...this.form.passports,
        {
          number: null,
          country: null,
          country_id: null,
          issue_date: null,
          expiration_date: null,
          file: null,
        },
      ];
    },
    addAddress(type) {
      if (type === 'Home') {
        this.homeAddress = {
          address: null,
          city: null,
          country: null,
          country_id: null,
          zip_code: null,
          type,
        };
        this.withAddressHome = true;
      }
      if (type === 'Local') {
        this.localAddress = {
          address: null,
          city: null,
          country: null,
          country_id: null,
          zip_code: null,
          type,
        };
        this.withAddressLocal = true;
      }
    },

    // Image manipulation
    imageSelected(imageFile) {
      const imageURL = URL.createObjectURL(imageFile);
      this.form.image = imageFile;
      this.form.image_url = imageURL;
      this.showCropper = true;
      this.imageType = imageFile.type;
    },
    imageCropped(blob) {
      const imageURL = URL.createObjectURL(blob);

      // Convert blob to file
      const file = blob;
      file.lastModifiedDate = new Date();
      file.name = 'image';

      this.form.image = file;
      this.form.image_url = imageURL;
    },
    addFlight() {
      this.form.flight = {
        flight_code: null,
        departure_date: null,
        arrival_date: null,
        airline: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
  .md-form-group .md-select { padding-left: 10px; }
  .small-text { font-size: .85em; line-height: 1.2em; }
  .flightFileContainer {
    position: relative;
    width: auto;
    margin-right: auto;
    max-height: 150px;
    max-width: 150px;

    .md-button {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
</style>
