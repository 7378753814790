<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div>
        <div
          v-if="$route.name === 'StudentFinancial'"
          class="md-layout"
        >
          <div class="md-layout-item">
            <div class="d-flex pt-4 pb-5">
              <h2 class="flex-1 m-0">
                <span class="text-success">PAID</span>:
                <span>{{ paid.toFixed(2) | currency('€') }}</span>
                -
                <span class="text-danger">DUE</span>:
                <span>{{ due.toFixed(2) | currency('€') }}</span>
              </h2>
              <md-button
                v-if="$route.name === 'StudentFinancial'"
                class="md-primary"
                @click="toCommissions(false)"
                @click.middle="toCommissions(true)"
              >
                COMMISSION STATUS
              </md-button>
            </div>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-table>
              <md-table-row>
                <md-table-cell
                  style="width: 20%"
                >
                  Expected Date
                </md-table-cell>
                <md-table-cell v-if="$route.name === 'StudentFinancial'">
                  Payment Date
                </md-table-cell>
                <md-table-cell>
                  Amount
                </md-table-cell>
                <md-table-cell>
                  Method
                </md-table-cell>
                <md-table-cell
                  v-if="$route.name === 'StudentFinancial'"
                  style="width: 10%"
                >
                  Status
                </md-table-cell>
                <md-table-cell>
                  <md-button
                    v-if="editable"
                    class="md-just-icon md-round md-primary pull-right"
                    @click="newPayment"
                  >
                    <md-icon>add</md-icon>
                    <md-tooltip>New Payment</md-tooltip>
                  </md-button>
                </md-table-cell>
              </md-table-row>

              <md-table-row
                v-for="(item, index) in payments"
                :key="item.key"
              >
                <md-table-cell>
                  <FormDate
                    v-model="item.expected_date"
                    label="Select date"
                    rules="required"
                    :disabled="!editable || ['Paid','Void'].includes(item.status)"
                  />
                </md-table-cell>
                <md-table-cell v-if="$route.name === 'StudentFinancial'">
                  <form-date
                    v-model="item.final_payment_date"
                    disabled
                  />
                </md-table-cell>
                <md-table-cell>
                  <FormNumber
                    v-model="item.amount"
                    currency
                    name="amount"
                    icon="euro"
                    rules="required"
                    :disabled="!editable || ['Paid','Void'].includes(item.status)"
                    @input="totalPaymentsCalc"
                  />
                </md-table-cell>
                <md-table-cell>
                  <PaymentMethodsSelect
                    v-model="item.payment_method_id"
                    rules="required"
                    :with-deleted="!editable || ['Paid','Void'].includes(item.status)"
                    :disabled="!editable || ['Paid','Void'].includes(item.status)"
                  />
                </md-table-cell>
                <md-table-cell v-if="['StudentFinancial','PackageDetails'].includes($route.name)">
                  <md-badge
                    v-if="item.amount < 0"
                    class="md-square md-danger"
                    md-content="Refunded"
                  />
                  <template v-else>
                    <md-badge
                      v-if="item.status === 'Waiting for Approval'"
                      class="md-square md-info"
                      md-content="Waiting for Approval"
                    />
                    <md-badge
                      v-if="item.status === 'Overdue'"
                      class="md-square md-danger"
                      md-content="Overdue"
                    />
                    <md-badge
                      v-if="item.status === 'Void'"
                      class="md-square md-default"
                      md-content="Void"
                    />
                    <md-badge
                      v-if="item.status === 'Unpaid'"
                      class="md-square md-warning"
                      md-content="Unpaid"
                    />
                    <md-badge
                      v-if="item.status === 'Rejected'"
                      class="md-square md-primary"
                      md-content="Rejected"
                    />
                    <md-badge
                      v-if="item.status === 'Paid'"
                      class="md-square md-success"
                      md-content="Paid"
                    />
                  </template>
                </md-table-cell>
                <md-table-cell>
                  <template v-if="$route.name === 'StudentFinancial'">
                    <md-button
                      v-if="item.status === 'Paid'"
                      class="md-simple md-icon-button md-info"
                      @click="showPaymentDetails(item)"
                    >
                      <md-icon>info</md-icon>
                      <md-tooltip>Show Details</md-tooltip>
                    </md-button>

                    <md-button
                      v-if="item.status === 'Paid'"
                      class="md-simple md-icon-button md-success"
                      @click="onDownloadInvoice(item)"
                    >
                      <md-icon>get_app</md-icon>
                      <md-tooltip>Download Invoice</md-tooltip>
                    </md-button>

                    <md-button
                      v-if="item.file"
                      class="md-simple md-icon-button md-primary"
                      @click="onDownloadProof(item)"
                    >
                      <md-icon>picture_as_pdf</md-icon>
                      <md-tooltip>Show Proof of Payment</md-tooltip>
                    </md-button>

                    <md-button
                      v-if="editable &&
                        (!item.status || !['Paid','Void'].includes(item.status))"
                      class="md-simple md-icon-button md-danger"
                      @click="deletePayment(item)"
                    >
                      <md-icon>close</md-icon>
                      <md-tooltip>Delete</md-tooltip>
                    </md-button>

                    <md-button
                      v-if="onlinePaymentsActive && ['Unpaid','Overdue'].includes(item.status)"
                      class="md-simple md-icon-button md-primary"
                      @click="onCreateButton(item)"
                    >
                      <md-icon>forward_to_inbox</md-icon>
                      <md-tooltip>Send Payment Button</md-tooltip>
                    </md-button>
                  </template>
                  <template v-else>
                    <md-button
                      v-if="((index > 0) && editable) &&
                        (!item.status || !['Paid','Void'].includes(item.status))"
                      class="md-simple md-icon-button md-danger"
                      @click="deletePayment(item)"
                    >
                      <md-icon>close</md-icon>
                      <md-tooltip>Delete</md-tooltip>
                    </md-button>
                  </template>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>

        <div class="md-layout mt-5">
          <div class="md-layout-item md-size-100">
            <md-table>
              <md-table-row>
                <md-table-cell>
                  Total Package
                </md-table-cell>
                <md-table-cell>
                  Total Payments
                </md-table-cell>
              </md-table-row>

              <md-table-row>
                <md-table-cell>
                  <md-field class="md-form-group">
                    <md-icon>euro</md-icon>
                    <md-input
                      :value="total.toFixed(2) | currency('')"
                      type="text"
                      disabled
                    />
                  </md-field>
                </md-table-cell>
                <md-table-cell>
                  <md-field class="md-form-group">
                    <md-icon>euro</md-icon>
                    <md-input
                      :value="totalPayments | currency('')"
                      type="text"
                      disabled
                    />
                  </md-field>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </div>
    </form>

    <template v-if="incomes.length">
      <hr class="my-5">

      <h5><b>Incomes from Student</b></h5>

      <table class="table table-hover">
        <tr>
          <th>Income ID</th>
          <th>Date</th>
          <th>Package Item</th>
          <th>Programme Start Date</th>
          <th>Remark</th>
          <th>Total</th>
        </tr>
        <tr
          v-for="i of incomes"
          :key="i.moneyflow_id"
        >
          <td>{{ i.moneyflow_id }}</td>
          <td>{{ dateFormat(i.date) }}</td>
          <td>{{ i.package_item.name }}</td>
          <td>{{ selProgramme.start_date }}</td>
          <td>{{ i.remark }}</td>
          <td>{{ moneyFormat(i.total) }}</td>
        </tr>
      </table>
    </template>

    <template v-if="expenditures.length">
      <hr class="my-5">

      <h5><b>Expenditures associated to Student</b></h5>

      <table class="table table-hover">
        <tr>
          <th>Expenditure ID</th>
          <th>Date</th>
          <th>Payment Method</th>
          <th>Category</th>
          <th>Remark</th>
          <th>Total</th>
        </tr>
        <tr
          v-for="i of expenditures"
          :key="i.moneyflow_id"
        >
          <td>{{ i.moneyflow_id }}</td>
          <td>{{ dateFormat(i.date) }}</td>
          <td>{{ i.payment_method.name }}</td>
          <td>{{ i.category.name }}</td>
          <td>{{ i.remark }}</td>
          <td>{{ moneyFormat(i.moneyflow_student.total) }}</td>
        </tr>
      </table>
    </template>

    <payment-details
      v-if="selPaymentDetails"
      :item="selPaymentDetails"
      @close="selPaymentDetails = null"
    />
  </ValidationObserver>
</template>
<script>
import { extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required } from 'vee-validate/dist/rules';
import {
  FormDate,
  FormNumber,
} from '@/components/Inputs';
import {
  PaymentMethodsSelect,
} from '@/components/Inputs/selects';

import moment from 'moment';
import PaymentDetails from '@/pages/Dashboard/Financial/PaymentDetails.vue';
import downloadFile from '@/utils/downloadFile';

extend('required', required);

export default {
  components: {
    FormDate,
    PaymentDetails,
    FormNumber,
    PaymentMethodsSelect,
  },
  props: {
    firstData: {
      type: Object,
      default() {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selProgramme: [],
      payments: [],
      package_items: [],
      incomes: [],
      expenditures: [],
      total: 0,
      totalPayments: 0,
      selPaymentDetails: null,
    };
  },
  computed: {
    paid() {
      return this.payments.reduce((a, b) => a + (b.status && b.status === 'Paid' ? parseFloat(b.total) : 0), 0);
    },
    due() {
      return this.total - this.paid;
    },
  },
  watch: {
    firstData: {
      immediate: true,
      handler(val) {
        this.payments = val.package_payments;
        this.package_items = val.package_items;
        this.incomes = val.incomes || [];
        this.expenditures = val.expenditures || [];
        this.selProgramme = val.selProgramme || [];

        if (this.package_items.length) {
          this.total = parseFloat(this.package_items.reduce((a, b) => {
            const tot = a + (parseFloat(b.price) * b.units);
            return tot;
          }, 0).toFixed(2));
        } else {
          this.total = 0;
        }

        this.totalPaymentsCalc();
      },
    },
  },
  methods: {
    updatePayments() {
      this.payments[0].amount = this.total.toFixed(2);
    },
    validate() {
      if (this.firstData.selProgramme && ['Partially Paid', 'Fully Paid', 'Pre-Enrolled'].includes(this.firstData.selProgramme.status)) {
        if (
          ['Fully Paid', 'Pre-Enrolled'].includes(this.firstData.selProgramme.status)
          && this.total !== this.paid
        ) {
          this.fireError('The total of payments is different from the package price.');
          return false;
        }

        if (this.total <= this.paid) {
          this.payments = this.payments.map((x) => {
            const z = x;
            if (['Unpaid', 'Waiting for Approval'].includes(z.status)) z.status = 'Void';
            return z;
          });
        } else if (this.total > this.totalPayments) {
          this.fireError('The total of payments is greater than the package price.');
          return false;
        } else if (this.total < this.totalPayments) {
          this.fireError('The total of payments is not enough to complete the package price.');
          return false;
        }
      } else {
        if (this.total > this.totalPayments) {
          this.fireError('The total of payments is not enough to complete the package price.');
          return false;
        }
        if (this.total < this.totalPayments) {
          this.fireError('The total of payments is greater than the package price.');
          return false;
        }
      }

      let validObjects = true;

      this.payments.forEach((x) => {
        const { expected_date, amount, payment_method_id } = x;

        if (!expected_date || !amount || !payment_method_id) validObjects = false;
      });

      if (!validObjects) {
        this.fireError('Complete all the columns for each payment please');
        return false;
      }

      return this.$refs.form.validate().then((res) => {
        this.$emit('on-validated', res, {
          package_payments: this.payments,
        }, 4);
        return res;
      });
    },

    onCreateButton(item) {
      this.fireConfirm('You are about to send an email', 'Are you sure?').then(() => {
        this.request(`payment/${item.package_payment_id}/send_button`, 'post', null, () => {
          this.fireSuccess('Button created succesfully, an Email has been sent to the student.');
        });
      });
    },
    newPayment() {
      this.payments.push({
        key: Math.random() * Date.now(),
        expected_date: null,
        amount: (this.total - this.totalPayments).toFixed(2),
        payment_method_id: null,
      });
    },
    showPaymentDetails(item) {
      const result = { ...item };
      result.package = this.firstData.selProgramme.package;
      result.package.programme = this.firstData.selProgramme.only([
        'student_id',
      ]);
      result.package.programme.student = this.firstData.studentData;
      this.selPaymentDetails = result;
    },
    onDownloadInvoice(item) {
      this.request(this.$API.SCHEDULED_PAYMENTS + item.package_payment_id, 'download', null, ({ data }) => {
        const filename = `${this.firstData.studentData.student_number}_${moment(item.payment_date).format('DD-MM-YYYY')}.pdf`;
        downloadFile(data, filename);
      });
    },
    onDownloadProof(item) {
      this.openFileUrl(item.file.file_id);
    },
    deletePayment(item) {
      this.payments.splice(this.payments.findIndex((i) => i.key === item.key), 1);
    },
    totalPaymentsCalc() {
      if (this.payments.length) {
        this.totalPayments = this.payments.reduce((a, b) => {
          if (!b.status || (b.status && b.status !== 'Void')) {
            return a + (b.amount ? parseFloat(b.amount) : 0);
          }
          return a + 0;
        }, 0);
      } else {
        this.totalPayments = 0;
      }

      this.totalPayments = this.totalPayments.toFixed(2);
    },
    toCommissions(isMiddleClick) {
      this.openLink({ name: 'StudentCommissions', params: this.$route.params }, isMiddleClick);
    },
  },
};
</script>
<style scoped lang="scss">
  ::v-deep .md-table {
    overflow: visible;

    table, .md-table-content {
      overflow: visible;
    }
  }

  .md-form-group .md-select { padding-left: 10px; }
  input.md-input { width: 100%; }
  .md-table-cell {
    padding: 0px 5px;
    border: unset !important;
  }
  .md-table-row:first-child .md-table-cell {
    border-bottom: 1px solid #ccc !important;
  }

  .md-badge {
    position: relative !important;
    height: auto;
  }
  .md-badge.md-danger {
    background: #f44336 !important;
    color: #fff;
  }
  .md-badge.md-default {
    background: #686565 !important;
    color: #fff;
  }
  .md-badge.md-warning {
    background: #ff9800 !important;
    color: #fff;
  }
  .md-badge.md-success {
    background: #4caf50 !important;
    color: #fff;
  }
  .md-badge.md-info {
    background: #00bcd4 !important;
    color: #fff;
  }
  .md-badge.md-primary {
    background: #9c27b0 !important;
    color: #fff;
  }
  table.table {
    width: 100%;
    text-align: left;

    &.table-hover tr:hover {
      background: rgba(0,0,0,.1);
    }

    tr:nth-child(even) {background: rgba(0,0,0,.05)}
  }
</style>
<style scoped>
::v-deep .vue-modal { z-index: 10 !important; }
</style>
