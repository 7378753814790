<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title" />
    <template slot="body">
      <cropper
        :src="image"
        :stencil-component="CircleStencil"
        @change="imageCropped"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-success"
        @click="finish"
      >
        Crop Image
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: {
    Cropper,
  },
  props: {
    img: {
      type: String,
      default: null,
    },
    imgType: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    CircleStencil,
    image: null,
    croppedImg: null,
  }),
  mounted() {
    this.image = this.img;
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },

    imageCropped({ canvas }) {
      canvas.toBlob((blob) => {
        this.croppedImg = blob;
      }, this.imgType);
    },

    finish() {
      console.log('finish');
      this.$emit('imageCropped', this.croppedImg);
      this.close();
    },
  },
};
</script>
<style></style>
