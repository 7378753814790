<template>
  <div
    class="md-layout"
    :class="{'line-through': item.is_invalid}"
  >
    <div class="md-layout-item md-size-30 md-small-size-100">
      <FormText
        v-model="item.name"
        label="Item"
        icon="description"
        disabled
      />
    </div>
    <div class="md-layout-item md-size-15 md-small-size-100">
      <FormNumber
        v-model="item.units"
        rules="required"
        icon="tag"
        label="Units"
        :disabled="!editable ||
          !canUpdateItems ||
          item.used ||
          item.is_invalid ||
          programmeStatus === 'Fully Paid'"
        @input="$emit('changed')"
      />
    </div>
    <div class="md-layout-item md-size-15 md-small-size-100">
      <FormNumber
        v-model="item.price"
        currency
        name="price"
        rules="required"
        icon="euro"
        label="Unit Price"
        :disabled="!editable ||
          !canUpdateItems ||
          item.fixed_price ||
          item.is_invalid ||
          programmeStatus === 'Fully Paid'"
        @input="$emit('changed')"
      />
    </div>
    <div class="md-layout-item md-size-15 md-small-size-100">
      <FormNumber
        :value="subtotal || 0"
        currency
        icon="euro"
        label="Subtotal"
        disabled
      />
    </div>
    <div class="md-layout-item md-size-20 md-small-size-100">
      <div class="d-flex">
        <FormCheckbox
          :value="item.used"
          :disabled="!editable || item.used"
          @change="itemUsedChanged"
        >
          Used
          <small v-if="item.package_package_item && item.package_package_item.used_at">
            ({{ dateFormat(item.package_package_item.used_at) }})
          </small>
        </FormCheckbox>

        <FormCheckbox
          v-if="['PackageDetails', 'StudentFinancial'].includes($route.name)"
          v-model="item.is_invalid"
          :disabled="!editable || item.is_invalid"
          class="ml-4"
          @change="itemValidChanged"
        >
          Inactive
        </FormCheckbox>
      </div>
    </div>
    <div class="md-layout-item md-size-5 md-small-size-100">
      <md-button
        v-if="!item.required &&
          editable &&
          canUpdateItems &&
          programmeStatus !== 'Fully Paid'"
        class="md-button md-just-icon md-danger md-round"
        @click="$emit('deleteItem')"
      >
        <md-icon>delete</md-icon>
      </md-button>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import moment from 'moment';
import {
  FormNumber,
  FormCheckbox,
  FormText,
} from '@/components/Inputs';

extend('required', required);

export default {
  components: {
    FormNumber,
    FormCheckbox,
    FormText,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    programmeStatus: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    item: {},
  }),
  computed: {
    canUpdateItems() {
      let can = true;

      for (const r of this.user.roles) {
        if (r.role_id === 1) return true;

        for (const p of r.permissions) {
          if (p.permission_id === 2) can = false;
        }
      }

      return can;
    },
    subtotal() {
      return parseFloat(this.item.price) * this.item.units;
    },
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.$emit('input', val);
      },
    },
  },
  mounted() {
    this.item = { ...this.value };
  },
  methods: {
    itemUsedChanged() {
      this.fireConfirm('WARNING', 'If you change this value you cannot revert it. Are you sure you want to do it?')
        .then(() => {
          if (this.programmeStatus) {
            this.request(`packages/items/${this.item.package_package_item.package_package_item_id}`, 'put', null, () => {
              this.item.used = true;
              this.item.package_package_item.used_at = moment();
              this.fireSuccess('Used value updated succesfully');
            });
            this.$forceUpdate();
          } else {
            this.item.used = true;
            this.fireSuccess('Used value updated succesfully');
          }
        })
        .catch(() => {
          this.item.used = false;
          this.$forceUpdate();
        });
    },
    itemValidChanged() {
      this.fireConfirm('WARNING', 'If you change this value you cannot revert it. Are you sure you want to do it?')
        .then(() => {
          this.item.is_invalid = true;
          this.$forceUpdate();
        })
        .catch(() => {
          this.item.is_invalid = false;
          this.$forceUpdate();
        });
    },
  },
};
</script>
