<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div>
        <h5 class="info-text">
          Let's start with the package information
        </h5>

        <div
          v-if="editable && canAddItems"
          class="md-layout mb-3"
        >
          <div class="md-layout-item md-size-50 ml-auto">
            <PackageItemsSelect
              :disabled="!editable"
              :filter="optionalFilter"
              @change="itemSelected = $event"
              @ready="onPackageItemsLoaded"
            />
          </div>
          <div class="md-layout-item md-size-25">
            <md-button
              class="md-raised md-success float-right"
              @click="addItem"
            >
              Add New Item
            </md-button>
          </div>
        </div>

        <template v-if="addedItems.length">
          <ThirdStepItem
            v-for="(item, index) in addedItems"
            :key="item.key"
            v-model="addedItems[index]"
            :programme-status="programmeStatus"
            :editable="editable"
            @deleteItem="deleteItem(index)"
            @changed="calculateTotal"
          />
        </template>

        <div class="md-layout mt-5">
          <div class="md-layout-item md-size-30 ml-auto">
            <md-field class="md-form-group">
              <md-icon>euro</md-icon>
              <label>Total</label>
              <md-input
                :value="total.toFixed(2) | currency('')"
                type="text"
                disabled
              />
            </md-field>
          </div>
          <div class="md-layout-item md-size-10 md-small-size-100" />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import {
  PackageItemsSelect,
} from '@/components/Inputs/selects';
import ThirdStepItem from './ThirdStepItem.vue';

export default {
  components: {
    ThirdStepItem,
    PackageItemsSelect,
  },
  props: {
    firstData: {
      type: Object,
      default() {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
    programmeStatus: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      addedItems: [],
      itemSelected: null,
      total: 0,
    };
  },
  computed: {
    canUpdateItems() {
      let can = true;

      for (const r of this.user.roles) {
        if (r.role_id === 1) return true;

        for (const p of r.permissions) {
          if (p.permission_id === 2) can = false;
        }
      }

      return can;
    },
    canAddItems() {
      if (!this.programmeStatus) return true;

      return this.canUpdateItems && [
        'Waiting for Approval',
        'Unpaid',
        'Partially Paid',
      ].includes(this.programmeStatus);
    },
  },
  watch: {
    firstData(val) {
      this.addedItems = val.package_items.map((x) => {
        x.used = x.used || false;
        x.key = Math.random() * Date.now();
        return x;
      });
      this.calculateTotal();
    },
  },
  methods: {
    optionalFilter(x) {
      return !this.addedItems.map((y) => y.package_item_id).includes(x.package_item_id);
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.$emit('on-validated', res, {
          package_items: this.addedItems,
        }, 3);
        return res;
      });
    },
    onPackageItemsLoaded() {
      if (!['PackageDetails', 'StudentFinancial'].includes(this.$route.name)) {
        this.addedItems = [...this.$store.getters['packageItems/required']];
        this.$emit('on-validated', true, {
          package_items: this.addedItems,
        }, 3);
      }
    },
    addItem() {
      if (this.itemSelected) {
        const itemToAdd = { ...this.itemSelected };

        itemToAdd.used = false;
        itemToAdd.price = itemToAdd.default_price;
        itemToAdd.key = Math.random() * Date.now();

        this.addedItems.push(itemToAdd);
        this.itemSelected = null;

        this.calculateTotal();
      }
    },
    deleteItem(index) {
      this.addedItems.splice(index, 1);
      this.calculateTotal();
    },
    calculateTotal() {
      this.total = this.addedItems.reduce((a, b) => a + (parseFloat(b.price) * b.units), 0);
    },
  },
};
</script>
<style scoped lang="scss">
  .md-form-group .md-select { padding-left: 10px; }
</style>
