var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout",class:{'line-through': _vm.item.is_invalid}},[_c('div',{staticClass:"md-layout-item md-size-30 md-small-size-100"},[_c('FormText',{attrs:{"label":"Item","icon":"description","disabled":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c('div',{staticClass:"md-layout-item md-size-15 md-small-size-100"},[_c('FormNumber',{attrs:{"rules":"required","icon":"tag","label":"Units","disabled":!_vm.editable ||
        !_vm.canUpdateItems ||
        _vm.item.used ||
        _vm.item.is_invalid ||
        _vm.programmeStatus === 'Fully Paid'},on:{"input":function($event){return _vm.$emit('changed')}},model:{value:(_vm.item.units),callback:function ($$v) {_vm.$set(_vm.item, "units", $$v)},expression:"item.units"}})],1),_c('div',{staticClass:"md-layout-item md-size-15 md-small-size-100"},[_c('FormNumber',{attrs:{"currency":"","name":"price","rules":"required","icon":"euro","label":"Unit Price","disabled":!_vm.editable ||
        !_vm.canUpdateItems ||
        _vm.item.fixed_price ||
        _vm.item.is_invalid ||
        _vm.programmeStatus === 'Fully Paid'},on:{"input":function($event){return _vm.$emit('changed')}},model:{value:(_vm.item.price),callback:function ($$v) {_vm.$set(_vm.item, "price", $$v)},expression:"item.price"}})],1),_c('div',{staticClass:"md-layout-item md-size-15 md-small-size-100"},[_c('FormNumber',{attrs:{"value":_vm.subtotal || 0,"currency":"","icon":"euro","label":"Subtotal","disabled":""}})],1),_c('div',{staticClass:"md-layout-item md-size-20 md-small-size-100"},[_c('div',{staticClass:"d-flex"},[_c('FormCheckbox',{attrs:{"value":_vm.item.used,"disabled":!_vm.editable || _vm.item.used},on:{"change":_vm.itemUsedChanged}},[_vm._v(" Used "),(_vm.item.package_package_item && _vm.item.package_package_item.used_at)?_c('small',[_vm._v(" ("+_vm._s(_vm.dateFormat(_vm.item.package_package_item.used_at))+") ")]):_vm._e()]),(['PackageDetails', 'StudentFinancial'].includes(_vm.$route.name))?_c('FormCheckbox',{staticClass:"ml-4",attrs:{"disabled":!_vm.editable || _vm.item.is_invalid},on:{"change":_vm.itemValidChanged},model:{value:(_vm.item.is_invalid),callback:function ($$v) {_vm.$set(_vm.item, "is_invalid", $$v)},expression:"item.is_invalid"}},[_vm._v(" Inactive ")]):_vm._e()],1)]),_c('div',{staticClass:"md-layout-item md-size-5 md-small-size-100"},[(!_vm.item.required &&
        _vm.editable &&
        _vm.canUpdateItems &&
        _vm.programmeStatus !== 'Fully Paid')?_c('md-button',{staticClass:"md-button md-just-icon md-danger md-round",on:{"click":function($event){return _vm.$emit('deleteItem')}}},[_c('md-icon',[_vm._v("delete")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }