<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div>
        <h5 class="info-text">
          Let's start with the programme information
        </h5>
        <div class="md-layout">
          <div
            v-if="['NewStudent', 'NewStudentProgramme'].includes($route.name)"
            class="md-layout-item md-size-95 ml-auto md-small-size-100"
          >
            <md-switch
              v-model="form.already_signed"
              :disabled="!signRequestActive"
            >
              Student already signed
            </md-switch>
            <SignRequestTemplatesSelect
              v-if="!form.already_signed && signRequestActive"
              v-model="form.template_id"
              :disabled="form.already_signed"
              rules="required"
            />

            <div
              v-else
              class="d-flex"
            >
              <FormUploader
                class="pb-2"
                @input="onTermsUploaded"
                @error="fireError"
              >
                <md-button class="md-info">
                  <md-icon>file_present</md-icon>
                  Upload Signed Terms & Conditions <span class="asterisk"> *</span>
                </md-button>

                <br>
                <small>File must be JPG/PNG/PDF max 5MB</small>
                <br>
                <small
                  v-if="form.terms_file"
                  class="text-success font-weight-bold"
                >
                  File to Upload: {{ form.terms_file.name }}
                </small> <br>
              </FormUploader>
              <div
                v-if="termsFileURL"
              >
                <md-button
                  :href="termsFileURL"
                  target="_blank"
                  class="md-primary"
                >
                  Download Uploaded Terms & Conditions File
                  <md-icon>download</md-icon>
                </md-button>
              </div>
            </div>
          </div>
          <div
            v-if="autoPlacementTestActive"
            class="md-layout-item md-size-95 ml-auto md-small-size-100"
          >
            <div class="d-flex">
              <ExamsSelect
                v-model="form.exam_id"
                class="flex-grow-1"
                :disabled="!editable"
              />
              <FormDate
                v-if="form.exam_id && form.exam_id !== 'null'"
                v-model="form.exam_expire_at"
                label="Exam Finish Date"
                rules="required"
                :disabled="!editable"
              />
            </div>
          </div>
          <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
            <AgentsSelect
              v-model="form.agent_id"
              :disabled="!editable || (userIsAgent && !userIsAgentsManager)"
              rules="required"
              :agent-groups="agentGroupsManagerIds"
              @change="onAgentSelected"
              @ready="onAgentsReady"
            />
            <CommissionsSelect
              v-if="selectedAgent"
              :key="form.agent_id"
              v-model="form.commission_id"
              :disabled="!editable"
              :agent="selectedAgent"
              rules="required"
              :no-percentage="!userIsFullAdmin"
            />
            <PeriodsSelect
              v-model="form.class_schedule_id"
              :disabled="!editable"
              rules="required"
            />
            <CoursesSelect
              v-model="form.course_id"
              :disabled="!editable"
              rules="required"
              @change="onCourseSelected"
            />
          </div>
          <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
            <md-switch
              v-model="form.no_date"
              :disabled="!editable"
              @change="onChangeNoDate"
            >
              Programme does not have a start date yet
            </md-switch>
            <form-date
              v-if="!form.no_date"
              v-model="form.start_date"
              name="start_date"
              label="Start Date"
              rules="required"
              :disabled="!editable"
            />
          </div>
          <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
            <form-number
              v-model="form.course_weeks"
              name="course_weeks"
              label="Course Weeks"
              rules="required"
              icon="notes"
              :disabled="!editable"
            />
          </div>
          <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
            <form-number
              v-model="form.holiday_weeks"
              name="holiday_weeks"
              label="Holiday Weeks"
              rules="required"
              icon="notes"
              :disabled="!editable"
            />
          </div>
          <div class="md-layout-item md-size-95 ml-auto md-small-size-100">
            <VisaTypesSelect
              v-model="form.visa_type"
              :disabled="!editable"
              rules="required"
            />
          </div>
          <div
            v-if="form.mkt_source_id"
            class="md-layout-item md-size-95 ml-auto md-small-size-100"
          >
            <MktSourcesSelect
              v-model="form.mkt_source_id"
              disabled
            />
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import {
  FormNumber, FormDate, FormUploader,
} from '@/components/Inputs';
import {
  VisaTypesSelect,
  ExamsSelect,
  CoursesSelect,
  AgentsSelect,
  CommissionsSelect,
  SignRequestTemplatesSelect,
  PeriodsSelect,
  MktSourcesSelect,
} from '@/components/Inputs/selects';
import moment from 'moment';

extend('required', required);

export default {
  components: {
    FormDate,
    FormNumber,
    FormUploader,
    ExamsSelect,
    VisaTypesSelect,
    CoursesSelect,
    AgentsSelect,
    CommissionsSelect,
    SignRequestTemplatesSelect,
    PeriodsSelect,
    MktSourcesSelect,
  },
  props: {
    firstData: {
      type: Object,
      default() {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        course_id: null,
        start_date: null,
        course_weeks: null,
        holiday_weeks: null,
        visa_type: null,
        agent_id: null,
        commission_id: null,
        template_id: null,
        already_signed: false,
        terms_file: null,
        exam_id: null,
        exam_expire_at: null,
        no_date: false,
        class_schedule_id: null,
        mkt_source_id: null,
      },
      termsFileURL: null,
    };
  },
  watch: {
    firstData(val) {
      Object.keys(this.form)
        .forEach((key) => {
          this.form[key] = val[key];
        });
      if (this.user.is_agent) {
        this.form.agent_id = this.user.user_id;
      }
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(async (res) => {
        if (this.form.already_signed && !this.form.terms_file) {
          this.fireError('Please upload Terms & Conditions to continue');
          this.$emit('on-validated', false, this.form, 2);
          return false;
        }

        if (this.$route.name !== 'StudentFinancial' && !this.form.no_date && moment(this.form.start_date).isSameOrBefore(moment())) {
          await this.fireConfirm('Warning!', 'Course Start Date is Same or Before today, are you sure you want to continue?');
        }

        const { mkt_source_id, ...form } = this.form;
        this.$emit('on-validated', res, form, 2);
        return res;
      });
    },
    onCourseSelected(course) {
      if (this.$route.name !== 'StudentFinancial' && course) {
        this.form.course_weeks = course.course_weeks;
        this.form.holiday_weeks = course.holiday_weeks;
      }
    },
    onAgentsReady(agents) {
      if (this.userIsAgent) {
        const agent = agents.find((a) => a.user_id === this.user.user_id);
        if (agent) {
          this.form.agent_id = agent.user_id;
          this.onAgentSelected(agent);
        }
      }
    },
    onAgentSelected(agent) {
      this.selectedAgent = agent;

      if (agent) {
        const commission_ids = agent.commissions.map((x) => x.agent_commission_id);

        if (!this.form.commission_id) {
          [this.form.commission_id] = commission_ids;
        }

        if (!commission_ids.includes(this.form.commission_id)) {
          this.form.commission_id = null;
        }
      } else {
        this.form.commission_id = null;
      }
    },
    onTermsUploaded(file) {
      this.form.terms_file = file;
      this.termsFileURL = URL.createObjectURL(file);
    },
    onChangeNoDate(val) {
      if (val) {
        this.form.start_date = moment('2000/01/01');
      } else {
        this.form.start_date = null;
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .md-form-group .md-select { padding-left: 10px; }
</style>
